import { Alert, Button, Divider, Form, Pagination, Space, Table, Typography } from 'antd'
import { selectMe } from 'app/redux/user/selectors'
import { useGetFormRegistersLazyQuery } from 'graphql/autogenerated'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { DEFAULT_PAGINATION, ROUTE_NAMES, TABLE_DROPDOWN_OPTIONS } from 'utils/constants'
import { getPathByName } from 'utils/utils'

const Title = Typography

export function ListFormRegisterPage() {
  const me = useSelector(selectMe)
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const history = useHistory()

  const [fetchGetFormRegisters, { data: formData, loading: formLoading, error: formError }] = useGetFormRegistersLazyQuery({
    fetchPolicy: 'no-cache',
  })

  const [dropDowntOpt, setDropDownOpt] = useState(TABLE_DROPDOWN_OPTIONS.active.value)

  const isFormActive = useMemo(() => {
    return dropDowntOpt === TABLE_DROPDOWN_OPTIONS.active.value
  }, [dropDowntOpt])

  const fetchList = () => {
    fetchGetFormRegisters({
      variables: {
        input: {},
      },
    })
  }

  const handleEdit = useCallback(
    record => {
      const pathname = getPathByName(ROUTE_NAMES.counselFormRegisterEdit, { id: record.id })
      history.push({ pathname })
    },
    [history],
  )

  useEffect(() => {
    fetchList()
  }, [page])

  const tableResource = useMemo(() => {
    return formData?.getFormRegisters?.data || []
  }, [formData])

  const columns = [
    {
      title: 'Tên',
      dataIndex: ['user', 'fullName'],
      key: 'fullName',
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'birthday',
      key: 'birthday',
    },
    {
      title: 'Giới tính',
      dataIndex: ['user', 'gender'],
      key: 'gender',
    },
    {
      title: 'Thành phố',
      dataIndex: ['user', 'city'],
      key: 'city',
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      key: 'email',
    },
    {
      title: 'Số điện thoại',
      dataIndex: ['user', 'phone'],
      key: 'phone',
    },
    {
      title: 'Số điện thoại người thân',
      dataIndex: 'relativePhoneNumber',
      key: 'relativePhoneNumber',
    },
    {
      title: 'Tôn giáo',
      dataIndex: 'religion',
      key: 'religion',
    },
    {
      title: 'Dân tộc',
      dataIndex: 'ethnicity',
      key: 'ethnicity',
    },
    {
      title: 'Vị trí trong gia đình',
      dataIndex: 'familyPosition',
      key: 'familyPosition',
    },
    {
      title: 'Mô tả vấn đề',
      dataIndex: 'issueDescription',
      key: 'issueDescription',
    },
    {
      title: 'Mong muốn',
      dataIndex: 'expectResult',
      key: 'expectResult',
    },
    {
      title: 'Thời gian',
      dataIndex: 'expectedTime',
      key: 'expectedTime',
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => {
        if (isFormActive) {
          return (
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => {
                  handleEdit(record)
                }}
              >
                Sửa
              </Button>
            </Space>
          )
        }
      },
    },
  ]

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Danh sách form đăng ký</Title>
      <Divider plain />
      {formError && <Alert type="error" message={formError.message} banner />}
      <Form form={form} component={false}>
        <Table dataSource={tableResource} columns={columns} rowKey="id" loading={formLoading} pagination={false} scroll={{ x: 1600 }} />
      </Form>
      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} />
      </div>
    </div>
  )
}
